<template>
  <div
    v-if="this.currentClaim !== undefined && this.currentClaim !== null"
    class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8"
  >
    <div class="text-center mb-10">
      <GenericButton @onClick="this.redirectToClaimListPage">
        {{ $t("Back") }}
      </GenericButton>
    </div>
    <div v-if="this.doesClaimExists">
      <div class="flex align-center flex-col">
        <h1
          class="text-3xl mb-2 pl-2 font-extrabold tracking-tight text-gray-900 text-center"
        >
          {{ this.currentClaim.friendlyId }}
        </h1>
        <div class="flex justify-center">
          <span
            v-bind:class="[
              getClaimStatusBannerStyle(this.currentClaim.status.color),
              'inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium',
            ]"
          >
            {{ this.currentClaim.status.text }}
          </span>
        </div>
      </div>

      <div class="mt-4">
        <GenericButton
          v-if="
            this.currentClaim.status.value !== 'resolved' &&
            this.currentClaim.requiredAction === 'upload_invoice'
          "
          @onClick="this.openClaimInvoiceUploadModal = true"
        >
          Ajouter la facture de réparation
        </GenericButton>
      </div>
      <div class="mt-4">
        <GenericButton
          v-if="this.currentClaim.status.value !== 'resolved'"
          @onClick="this.openUpdateStatusModal = true"
        >
          Mettre à jour le statut
        </GenericButton>
      </div>
      <div class="mt-4">
        <GenericButton
          v-if="
            this.currentClaim.status.value !== 'resolved' &&
            this.currentClaim.requiredAction === 'upload_quotation'
          "
          @onClick="this.openClaimQuotationUploadModal = true"
        >
          Ajouter le devis de réparation
        </GenericButton>
      </div>
      <div class="mt-4">
        <GenericButton
          v-if="this.currentClaim.claimDeductibleWaitingForPayment"
          @onClick="requestAndCopyClaimDeductiblePaymentUrl"
        >
          Copier le lien de paiement de la franchise
        </GenericButton>
      </div>
      <RequiredAction
        class="mt-10"
        v-if="this.doesClaimExists && this.currentClaim.requiredAction"
        :claim="this.currentClaim"
      />

      <ClaimHistoricalChanges
        v-if="this.doesClaimExists"
        v-bind:claim="this.currentClaim"
        class="border border-gray-300 mt-10"
      />
      <InvoiceInformation class="mt-10" v-bind:claim="this.currentClaim" />
      <QuotationInformation class="mt-10" v-bind:claim="this.currentClaim" />
      <VoucherInformation
        class="mt-10"
        v-bind:voucher="this.currentClaim.voucher"
      />
      <ClaimCoverageInformation
        v-if="this.doesClaimExists && this.currentClaim.coverages"
        class="mt-10"
        v-bind:claim="this.currentClaim"
      />
      <ClaimInformation
        v-if="this.doesClaimExists"
        class="mt-10"
        v-bind:claim="this.currentClaim"
      />
      <PlanSaleInformation
        v-if="this.doesClaimExists"
        class="mt-10"
        v-bind:claim="this.currentClaim"
      />
      <ClaimFormResponses
        v-if="this.doesClaimExists"
        class="mt-10"
        v-bind:claim="this.currentClaim"
      />

      <UpdateClaimStatusModal
        v-bind:open="this.openUpdateStatusModal"
        v-bind:claim="this.currentClaim"
        @close="this.openUpdateStatusModal = false"
      />
      <ClaimInvoiceUploadModal
        v-bind:open="this.openClaimInvoiceUploadModal"
        v-bind:claim="this.currentClaim"
        @close="this.openClaimInvoiceUploadModal = false"
      />
      <ClaimQuotationUploadModal
        v-bind:open="this.openClaimQuotationUploadModal"
        v-bind:claim="this.currentClaim"
        @close="this.openClaimQuotationUploadModal = false"
      />
    </div>
    <ClaimNotFound v-else />
  </div>
</template>

<script>
import GenericButton from "@/components/utils/GenericButton.vue";
import ClaimInformation from "@/components/claim/list/details/information/ClaimInformation.vue";
import PlanSaleInformation from "@/components/claim/list/details/information/PlanSaleInformation.vue";
import ClaimNotFound from "@/components/claim/list/utils/ClaimNotFound.vue";
import ClaimFormResponses from "@/components/claim/list/details/information/ClaimFormResponses.vue";
import QuotationInformation from "@/components/claim/list/details/quotation/QuotationInformation.vue";
import ClaimHistoricalChanges from "@/components/claim/list/details/information/ClaimHistoricalChanges.vue";
import { mapActions, mapGetters, mapState } from "vuex";
import UpdateClaimStatusModal from "@/components/claim/list/details/information/action/UpdateClaimStatusModal.vue";
import RequiredAction from "@/components/claim/list/details/RequiredAction.vue";
import InvoiceInformation from "@/components/claim/list/details/invoice/InvoiceInformation.vue";
import ClaimInvoiceUploadModal from "@/components/claim/list/details/information/action/ClaimInvoiceUploadModal.vue";
import ClaimQuotationUploadModal from "@/components/claim/list/details/information/action/ClaimQuotationUploadModal.vue";
import VoucherInformation from "@/components/claim/list/details/information/VoucherInformation.vue";
import ClaimCoverageInformation from "@/components/claim/list/details/information/ClaimCoverageInformation.vue";
import BackofficeService from "@/api/services/backoffice";

export default {
  components: {
    ClaimCoverageInformation,
    VoucherInformation,
    ClaimQuotationUploadModal,
    ClaimInvoiceUploadModal,
    InvoiceInformation,
    RequiredAction,
    UpdateClaimStatusModal,
    ClaimHistoricalChanges,
    QuotationInformation,
    GenericButton,
    ClaimInformation,
    PlanSaleInformation,
    ClaimNotFound,
    ClaimFormResponses,
  },

  computed: {
    ...mapGetters("claims", ["doesClaimExists"]),
    ...mapState("claims", ["currentClaim"]),
  },

  data: () => {
    return {
      openUpdateStatusModal: false,
      openClaimInvoiceUploadModal: false,
      openClaimQuotationUploadModal: false,
    };
  },

  beforeMount() {
    this.getClaim(this.$route.params.id);
  },

  methods: {
    ...mapActions("claims", ["getClaim", "reset"]),
    ...mapActions("notifications", ["notify"]),

    getClaimStatusBannerStyle: function (color) {
      return `bg-${color}-100 text-${color}-800`;
    },

    redirectToClaimListPage: function () {
      this.$router.back();
    },

    requestAndCopyClaimDeductiblePaymentUrl: async function () {
      try {
        const response = await BackofficeService.getClaimDeductiblePaymentUrl(
          this.$route.params.id
        );
        this.copyToClipboard(response.data.url);
      } catch (e) {
        console.error(e);
      }
    },

    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(() => {
        this.notify({
          category: "simple",
          type: "success",
          title: "Link copied to clipboard!",
        });
      });
    },
  },
};
</script>
