<template>
  <div v-if="store">
    <StoreGeneralInformation
      :general-informations="store.generalInformations"
      :sales-field-managers="store.salesFieldManagers"
      @update:selectedSalesFieldManager="onSalesFieldManagerChange"
      @validateStoreConfig="validateStoreConfig"
    />
    <StoreConfigurationGeneralInformations
      class="mt-10"
      :general-informations="store.configurationGeneralInformations"
      :is-store-setup="isStoreSetup"
      @validate="validateConfigurationGeneralInformations"
      @reject="rejectConfigurationGeneralInformations"
    />
    <StoreConfigurationContactInformations
      class="mt-10"
      :contact-informations="store.configurationContactInformations"
      :is-store-setup="isStoreSetup"
      @validate="validateConfigurationContactInformations"
      @reject="rejectConfigurationContactInformations"
    />
    <StoreConfigurationKyc
      class="mt-10"
      :kyc="store.configurationKyc"
      :is-store-setup="isStoreSetup"
      @validate="validateConfigurationKyc"
      @reject="rejectConfigurationKyc"
    />
    <StoreConfigurationAfterSalesService
      class="mt-10"
      :after-sales-service="store.configurationAfterSalesService"
      :is-store-setup="isStoreSetup"
      @validate="validateConfigurationAfterSalesService"
      @reject="rejectConfigurationAfterSalesService"
    />
    <StoreBillingInformation
      class="mt-10"
      :store="store"
      @remunerationModelUpdated="getStore"
    />
    <StoreDiscountInformation
      class="mt-10"
      :discount="store.discount"
      :challenges="store.challenges"
      :store-id="store.generalInformations.id"
      @discountUpdated="getStore"
      @challengeUpdated="getStore"
    />
    <StoreBusinessVerticals
      class="mt-10"
      :store="store"
    />
    <StoreMatchedContracts
      class="mt-10"
      :matched-contracts="store.matchedContracts"
    />
    <StoreUsersInformation
      class="mt-10"
      :users="store.users"
      :store-id="store.generalInformations.id"
    />
    <SellersInformation
      class="mt-10"
      :sellers="store.sellers"
      @getSellers="getSellers"
    />
    <StoreTechnicalConfiguration
      class="mt-10"
      :technical-configuration="store.technicalConfiguration"
    />
    <StoreApiKeysInformation
      class="mt-10"
      :api-keys="store.apiKeys"
    />
  </div>
</template>

<script>
import StoreGeneralInformation from "@/views/stores/details/StoreGeneralInformation.vue";
import StoreBusinessVerticals from "@/views/stores/details/StoreBusinessVerticals.vue";
import StoreMatchedContracts from "@/views/stores/details/StoreMatchedContracts.vue";
import StoreDiscountInformation from "@/views/stores/details/StoreDiscountInformation.vue";
import StoreBillingInformation from "@/views/stores/details/StoreBillingInformation.vue";
import SellersInformation from "@/views/stores/details/SellersInformation.vue";
import StoreTechnicalConfiguration from "@/views/stores/details/StoreTechnicalConfiguration.vue";
import StoreApiKeysInformation from "@/views/stores/details/StoreApiKeysInformation.vue";
import StoreUsersInformation from "@/views/stores/details/StoreUsersInformation.vue";
import StoreConfigurationGeneralInformations from "@/views/stores/details/StoreConfigurationGeneralInformations.vue";
import StoreConfigurationContactInformations from "@/views/stores/details/StoreConfigurationContactInformations.vue";
import StoreConfigurationKyc from "@/views/stores/details/StoreConfigurationKyc.vue";
import StoreConfigurationAfterSalesService from "@/views/stores/details/StoreConfigurationAfterSalesService.vue";

import BackofficeService from "@/api/services/backoffice.js";

import { mapActions } from "vuex";

export default {
  components: {
    StoreApiKeysInformation,
    StoreTechnicalConfiguration,
    SellersInformation,
    StoreUsersInformation,
    StoreBillingInformation,
    StoreDiscountInformation,
    StoreMatchedContracts,
    StoreBusinessVerticals,
    StoreGeneralInformation,
    StoreConfigurationGeneralInformations,
    StoreConfigurationContactInformations,
    StoreConfigurationKyc,
    StoreConfigurationAfterSalesService
  },

  data() {
    return {
      store: null,
    };
  },

  async beforeMount() {
    await this.getStore();
  },

  computed: {
    isStoreSetup() {
      return this.store?.generalInformations?.status === "setup";
    }
  },

  methods: {
    ...mapActions("notifications", ["notify"]),
    async getStore() {
      const storeId = this.$route.params.id;
      if (storeId) {
        const response = await BackofficeService.getStore(storeId);
        this.store = response.data;
      }
    },
    async onSalesFieldManagerChange(salesFieldManagerId) {
      const storeId = this.$route.params.id;
      if (storeId) {
        await BackofficeService.updateStoreSalesFieldManager(
          storeId,
          salesFieldManagerId
        );
      }
    },
    getSellers() {
      this.getStore();
    },
    validateConfigurationGeneralInformations() {
      this.updateStoreConfigurationStatus("general_informations", "validated");
    },
    rejectConfigurationGeneralInformations() {
      this.updateStoreConfigurationStatus("general_informations", "rejected");
    },
    validateConfigurationContactInformations() {
      this.updateStoreConfigurationStatus("contact_informations", "validated");
    },
    rejectConfigurationContactInformations() {
      this.updateStoreConfigurationStatus("contact_informations", "rejected");
    },
    validateConfigurationKyc() {
      this.updateStoreConfigurationStatus("kyc", "validated");
    },
    rejectConfigurationKyc() {
      this.updateStoreConfigurationStatus("kyc", "rejected");
    },
    validateConfigurationAfterSalesService() {
      this.updateStoreConfigurationStatus("after_sales_service", "validated");
    },
    rejectConfigurationAfterSalesService() {
      this.updateStoreConfigurationStatus("after_sales_service", "rejected");
    },
    async validateStoreConfig() {
      const storeId = this.$route.params.id;
      try {
        await BackofficeService.updateStoreStatus(storeId, "active");
        await this.notify({
          category: "simple",
          type: "success",
          title: "Mise à jour effectuée",
        });
      } catch (error) {
        console.error(error);
        await this.notify({
          category: "simple",
          type: "error",
          title: "Une erreur est survenue",
          text: error.response?.data?.error || error.message,
        });
      }
      await this.getStore(); // Refresh the store (status has changed)
    },
    async updateStoreConfigurationStatus(step, status) {
      const storeId = this.$route.params.id;
      try {
        await BackofficeService.updateStoreConfigurationStatus(storeId, step, status);
        await this.notify({
          category: "simple",
          type: "success",
          title: "Mise à jour effectuée",
        });
      } catch (error) {
        await this.notify({
          category: "simple",
          type: "error",
          title: "Une erreur est survenue",
          text: error.response?.data?.error || error.message,
        });
      }
      await this.getStore(); // Refresh the store (status has changed)
    }
  },
};
</script>
